import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Accordion, Container, Card } from 'react-bootstrap'
import ReactMarkdown from 'react-markdown'

class FAQPage extends React.Component {
    constructor (props) {
        super(props)

        const { mdx } = props.data
        const { frontmatter } = mdx

        this.state = {
            ...frontmatter
        }
    }

    componentDidMount() {}

    render() {
        return (
            <Layout>
                <SEO title="FAQ" />
                <Container className="bg-green-light rounded my-md-4 py-4 text-justify">
                    <h4 className="mb-4">{this.state.title}</h4>
                    {
                        this.state.description ?
                            <ReactMarkdown parserOptions={{ commonmark: true }} source={this.state.description} />
                         : ''
                    }
                    {
                        this.state.question_groups?.map(({theme, questions}, index) => {
                            return (<div key={"theme_" + index} className="my-3">
                                    <h5>{theme}</h5>
                                    <Accordion>
                                        {
                                            questions?.map(({question, answer}, index_question) => {
                                                return (
                                                    <Card key={"question_" + index_question}>
                                                        <Accordion.Toggle as={Card.Header} eventKey={index_question}>
                                                            {question}
                                                        </Accordion.Toggle>
                                                        <Accordion.Collapse eventKey={index_question}>
                                                            <Card.Body>
                                                                <ReactMarkdown parserOptions={{ commonmark: true }} source={answer} />
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                )
                                            })
                                        }
                                    </Accordion>
                                </div>
                            )
                        })
                    }
                </Container>

            </Layout>
          )
        }
}

export default FAQPage

export const pageQuery = graphql`
    query($id: String) {
        mdx(id: { eq: $id }) {
            frontmatter {
                title
                description
                question_groups {
                    theme
                    questions {
                        question
                        answer
                    }
                }
            }
        }
    }`
